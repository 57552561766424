<template>
  <div class="list">
    <Column class="list__wrapper">
      <div class="list__header"
           v-if="$slots['list-header']">
        <slot name="list-header"/>
      </div>
      <div class="list__content"
           :style="style"
           ref="list-content">
        <slot name="list-content"/>
      </div>
    </Column>
  </div>
</template>

<script>
export default {
  name: 'List',
  data() {
    return {
      style: {
        margin: 0,
      },
    };
  },
  mounted() {
    const element = this.$refs['list-content'];
    this.style.margin = `0 -${element.offsetWidth - element.clientWidth}px 0 0`;
  },
};
</script>
